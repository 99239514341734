import { DEFAULT_LOCALE } from '../constants'

export const formatValue: (
  value: number | string,
  symbol?: string,
  symbolBefore?: boolean,
) => string = (value, symbol, symbolBefore) => {
  if (!value) {
    return ''
  }
  const numVal = typeof value === 'string' ? Number(value) : value
  return `${symbol && symbolBefore ? symbol : ''}${numVal.toLocaleString(
    DEFAULT_LOCALE,
  )}${symbol && !symbolBefore ? symbol : ''}`
}
