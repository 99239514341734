import { css } from '@emotion/react'
import { fontSize16 } from '../../constants'

export const text = css`
  height: 20px;
  width: 100%;
  color: var(--shade-blue);
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px;
  text-align: center;
`
export const input = css`
  height: 40px;
  width: 345px;
  color: var(--blue-grey);
  ${fontSize16}
`

export const singleLine = css`
  box-sizing: border-box;
  padding: 40px;
  width: 100%;
  display: inline-flex;
`
