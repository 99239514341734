import { css } from '@emotion/react'
import * as React from 'react'
import * as styles from './card.styles'

export interface CardProps {
  title?: string
  maxWidth?: string
  width?: string
  children: React.ReactNode
}
export const Card: React.FC<CardProps> = (props) => {
  const { children, title, maxWidth, width } = props

  const addMaxWidth = maxWidth ? ` max-width: ${maxWidth};` : ''
  const addWidth = width ? ` width: ${width};` : ''

  return (
    <div
      css={css`
        ${styles.card}${addMaxWidth}${addWidth}
      `}
    >
      {!title ? null : <div css={styles.cardTitle}>{title}</div>}
      {children}
    </div>
  )
}
