import { css } from '@emotion/react'
import { cssColor, cssText, mfThemeVar } from '@mulliganfunding/fe-style'
import { defFont } from './../../utils'

export const tooltipText = css(
  defFont('r', 14, 20, 0.3, mfThemeVar.TEXT_DEFAULT),
  `
  label: tool-tip-text;
  margin: 0px;
  text-align: left;
  padding: 2px;

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: 130px;
    top: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: ${cssColor.PRIMARY};
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 131px;
    top: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top-color: ${cssColor.NEUTRAL_0};
  }
`,
)
