import { css, SerializedStyles } from '@emotion/react'
import { mfThemeVar } from '@mulliganfunding/fe-style'
import * as React from 'react'
import * as styles from './tool-tip.styles'

export interface ToolTipProps {
  addCss: SerializedStyles
  display: boolean
  children?: React.ReactNode
}
export const ToolTip: React.FC<ToolTipProps> = (props) => {
  const { addCss, display, children } = props

  const toolTipStyle = display
    ? css(
        {
          label: 'tool-tip',
          display: 'inline-block',
          position: 'relative',
          border: `1px solid var(${mfThemeVar.PRIMARY});`,
          background: 'rgba(255,255,255)',
          width: '155px',
          borderRadius: '2px',
          boxShadow: '0 2px 14px 0 rgba(0,0,0,0.14)',
          marginBottom: '15px',
          padding: '5px',
          cursor: 'default',
        },
        addCss,
      )
    : css('display: none;')

  return (
    <div css={toolTipStyle}>
      <div css={styles.tooltipText}>{children}</div>
    </div>
  )
}
