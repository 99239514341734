import { css } from '@emotion/react'
import { cssColor, cssText, mfThemeVar } from '@mulliganfunding/fe-style'
import lockIcon from './../../assets/imgs/lock-icon.svg'
import { PATTERN_ORANGEBAR } from '../../constants/images.js'

export const sliderThumbStyles = {
  cursor: 'pointer',
  height: '39px',
  width: '17.62px',
  border: '2px solid white',
  borderRadius: '10px',
  backgroundColor: `${cssColor.TERTIARY}`,
  boxShadow: '0 2px 14px 0 rgba(0,0,0,0.15)',
}
export const sliderTrackStyles = {
  label: 'slider-track-styles',
  width: '100%',
  height: '10px',
  cursor: 'pointer',
  background: '#CBCBCB',

  /* FF*/
  '::-moz-range-progress': {
    backgroundColor: `${cssColor.TERTIARY_LIGHT}`,
    background: `url(${PATTERN_ORANGEBAR}) center no-repeat scroll`,
  },
  '::-moz-range-track': {
    backgroundColor: '#CBCBCB',
  },
  /* IE*/
  '::-ms-fill-lower': {
    backgroundColor: `${cssColor.TERTIARY_LIGHT}`,
  },
  '::-ms-fill-upper': {
    backgroundColor: '#CBCBCB',
  },
}
export const container = css`
  label: slider-container;
  display: flex;
  flex-direction: row;
  width: 652px;
`
export const label = css`
  label: label;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  font-family: ${cssText.fontFamily.MONTSERRAT_SANS_SERIF}
    ${cssText.fontSize._16px};
`
export const labelBlock = css`
  label: label-block;
  display: flex;
  flex-direction: column;
  flex: 1;
  input {
    :disabled {
      background-color: ${cssColor.NEUTRAL_15};
      border: none;
    }
  }
`
const minAndMaxLabel = `
  label: min-and-max-label;
  flex: 1;
  font-family: ${cssText.fontFamily.MONTSERRAT_SANS_SERIF}
  ${cssText.fontSize._14px}
  color: var(${mfThemeVar.TEXT_LIGHT});
  letter-spacing: : 0.3px;
`
export const maxLabel = css`
  label: max-label;
  ${minAndMaxLabel}
  text-align: right;
`
export const minLabel = css`
  label: min-label;
  ${minAndMaxLabel}
  text-align: left;
`
export const shadowedValue = css`
  label: shadowed-value;
  color: var(--text-light);
  font-family: ${cssText.fontFamily.MONTSERRAT_SANS_SERIF}
  ${cssText.fontSize._14px}
  ${cssText.letterSpacing._pos0p3}
  line-height: 18px;
  text-align: right;
`
export const sliderBlock = css({
  boxSizing: 'border-box',
  paddingTop: '24px',
  display: 'flex',
  flexDirection: 'column',
  flex: '3',
  marginLeft: '40px',
  'input[type=range]': {
    WebkitAppearance: 'none',
    backgroundColor: 'transparent',
  },
  'input[type=range]:focus': {
    outline: 'none',
  },
  'input[type=text]': {},
})
export const sliderLabels = css`
  label: slider-labels;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`
export const textInput = css`
  box-sizing: border-box;
  height: 39px;
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  background-color: ${cssColor.TEXT_DEFAULT_ON_DARK};
  color: var(${mfThemeVar.TEXT_DEFAULT});
  ${cssText.fontSize._24px}
  ${cssText.fontWeight._SEMI_BOLD}
  letter-spacing: 0.45px;
  ${cssText.lineHeight._24px}
  text-align: right;
  padding-right: 10px;
`
export const hideInfo = css`
  label: hide-info;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s, overflow 0s;
`

export const showInfo = css`
  label: show-info;
  max-width: 652px;
  max-height: 128px;
  transition: max-height 1s, overflow 1s;
`
export const lockIconDiv = css`
  label: lock-icon-div;
  border-width: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  padding-top: 60px;
  padding-left: 10px;
  background: url(${lockIcon}) center no-repeat scroll;
`
