import * as React from 'react'
import { capitalize } from '../../utils'
import * as styles from './radio-button-group.styles'

export interface RadioButtonGroupProps {
  title: string
  value: string
  name: string
  optionsList: Array<string>
  symbol?: string
  handleOnChange: (newValue: string) => void
}
export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (props) => {
  const { title, name, value, optionsList, symbol, handleOnChange } = props

  return (
    <div css={styles.sectionBlock}>
      <div css={styles.radioBlock}>
        <div css={styles.title}>{title}</div>
        <div css={styles.radioGroup}>
          {[...optionsList]?.sort().map((opt) => {
            return (
              <label
                key={`${opt}-label`}
                css={styles.radioOption}
                htmlFor={`${opt}-value`}
              >
                {optionsList.length > 1 && (
                  <input
                    key={`${opt}-value`}
                    id={`${opt}-value`}
                    name={name}
                    type="radio"
                    value={opt}
                    css={styles.radioButton}
                    checked={value == opt}
                    onChange={({ target: { value } }) => handleOnChange(value)}
                  />
                )}
                <div css={styles.value}>
                  {capitalize(opt)}
                  {symbol && symbol}
                </div>
              </label>
            )
          })}
        </div>
      </div>
    </div>
  )
}
