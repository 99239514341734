export const getEnv = () => {
  const logAndReturn = (env, type = 'log') => {
    console[type]('env: ' + env)
    return env
  }
  const host = window.location.host
  if (host.includes('local-dev')) {
    return logAndReturn('local-dev')
  }
  if (host.includes('.dev.')) {
    return logAndReturn('dev')
  }
  if (host.includes('.stg.')) {
    return logAndReturn('stg')
  }
  if (host.includes('.prod.') || host.includes('mulliganfunding.com')) {
    return logAndReturn('prod')
  }
  return logAndReturn('unknown', 'error')
}
