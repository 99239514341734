import { css } from '@emotion/react'
import { cssColor, cssText, cssTyp } from '@mulliganfunding/fe-style'

const textStyles = css`
  label: text-styles;
  color: ${cssColor.NEUTRAL_0};
  ${cssTyp._BODY}
  ${cssText.fontWeight._SEMI_BOLD}
`
export const itemCardWrapper = css`
  label: item-card-wrapper;
  box-sizing: border-box;
  width: 100%;
  border-radius: 6px;
  background-color: ${cssColor.TERTIARY_DARK};
`
export const itemCard = css`
  label: item-card;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px 25px;
  background-color: ${cssColor.TERTIARY_DARK};
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`
export const itemCardWithChildren = css`
  label: item-card-with-children;
  box-sizing: border-box;
  padding: 8px 25px;
  width: 100%;
  border-radius: 6px;
  background-color: ${cssColor.TERTIARY_DARK});
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  max-height: 60px;
`
export const label = css`
  label: label;
  ${textStyles}
  flex: 3;
`
export const labelWithChildren = css`
  label: label-with-children;
  ${label}
  padding-top: 8px;
`
export const spacer = css`
  label: spacer;
  flex: 1;
`
export const value = css`
  label: value;
  ${textStyles}
  text-align: right;
  ${cssText.fontSize._20px}
`
export const valueBlock = css`
  label: value-block;
  flex: 2;
  display: flex;
  flex-direction: row;
`
export const verticalSeparator = css`
  label: vertical-separator;
  border-left: 2px solid white;
  margin: 0 10px;
`
export const itemCardSubtextWrapper = css`
  label: item-card-subtext-wrapper;
  padding-bottom: 10px;
`
export const itemCardSubtext = css`
  label: item-card-subtext;
  height: 45px;
  border-radius: 6px;
  background-color: rgba(62, 105, 119, 0.5);
  margin: auto;
  width: 90%;
`
export const subtext = css`
  label: subtext;
  padding: 10px;
  display: flex;
  flex-direction: row;
`
export const subtextLabel = css`
  label: subtext-label;
  ${textStyles}
  flex: 3;
  height: 24px;
  width: 225px;
`
export const infoIcon = css`
  label: info-icon;
  height: 18.33px;
  width: 18.33px;
  padding-top: 3px;
  padding-left: 5px;
`
