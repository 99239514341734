import { Guarantor, InputMode } from './../../constants'
import React, { useState } from 'react'
import * as styles from './signee.styles'
import { css } from '@emotion/react'
import { InputOrDisplayBox } from '../../components'

type SigneeProps = {
  guarantor: Guarantor
  onChange?: (guarantorContactId: string, email: string) => void
  isSubmitPressed: boolean
}

export const Signee: React.FC<SigneeProps> = ({
  guarantor,
  onChange,
  isSubmitPressed,
}) => {
  const [email, setEmail] = useState('')

  const onChangeHandler = (changeEvent, guarantorContactId) => {
    const email = changeEvent.target.value
    setEmail(email)
    onChange(guarantorContactId, email)
  }

  const inputMode = guarantor.guarantor_email
    ? InputMode.display
    : InputMode.input
  return (
    <div css={styles.whiteBlock}>
      <div css={styles.label}>Full Name</div>
      <div css={styles.displayBox}>{guarantor.guarantor_name}</div>
      <div css={css(styles.label, 'margin-top: 26px;')}>Email</div>
      <InputOrDisplayBox
        mode={inputMode}
        value={guarantor.guarantor_email || email}
        onChange={(e) => onChangeHandler(e, guarantor.guarantor_contactid)}
        isSubmitPressed={isSubmitPressed}
      />
    </div>
  )
}
