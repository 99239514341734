const logColorUtil = (
  color: string,
  bgColor: string,
  title: string,
  ...args: any[]
): void => {
  const strTitle = typeof title === 'string' ? title : JSON.stringify(title)
  console.log(
    `%c ${strTitle} `,
    `color: ${color}; background-color: ${bgColor};`,
    ...args,
  )
}

// TODO add to library, fe-util?
export const logColor = {
  baby: (title, ...args) => logColorUtil('#0000FF', '#DDDDFF', title, ...args),
  blue: (title, ...args) => logColorUtil('white', 'blue', title, ...args),
  gray: (title, ...args) => logColorUtil('#006600', '#dddddd', title, ...args),
  green: (title, ...args) => logColorUtil('white', 'green', title, ...args),
  lime: (title, ...args) => logColorUtil('black', 'lime', title, ...args),
  orange: (title, ...args) =>
    logColorUtil('#331100', '#FFCC77', title, ...args),
  pink: (title, ...args) => logColorUtil('#FF0000', '#FFDDDD', title, ...args),
  purple: (title, ...args) => logColorUtil('yellow', 'purple', title, ...args),
  red: (title, ...args) => logColorUtil('white', 'red', title, ...args),
  yellow: (title, ...args) => logColorUtil('blue', 'yellow', title, ...args),
}
