export const roundToNearestQuarter = (value: number) => {
  return Math.round(value * 4) / 4
}
export const roundToNearestTenth = (value: number) => {
  return Math.round(value * 10) / 10
}
// Round to at most 2 decimal places (only if necessary)
export const roundToNearestHundreth = (value: number) => {
  return Math.round(value * 100) / 100
}
export const roundToNearestThousandth = (value: number) => {
  return Math.round(value * 1000) / 1000
}
export const roundToNearestTenThousandth = (value: number) => {
  return Math.round(value * 10000) / 10000
}
export const decimalToPercent = (num) => {
  return num * 100
}
export const percentToDecimal = (num) => {
  return num / 100
}
