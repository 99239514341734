import React from 'react'
import { Modal, SubmitButton } from '..'
import * as styles from './error-modal.styles'
import iconError from '../../assets/imgs/icon-error.svg'

type Props = {
  isDisplay: boolean
  handleClose: () => void
  handleTryAgain?: () => void
}

export const ErrorModal: React.FC<Props> = ({
  isDisplay,
  handleClose,
  handleTryAgain,
}) => {
  // isDisplay, controls modal show/hide
  if (!isDisplay) return null
  // The "TRY AGAIN" button will execute a handleTryAgain handler if it exists
  // If no handleTryAgain handler has been provided, it will execute the handleClose handler
  const tryAgainClickHandler = handleTryAgain ? handleTryAgain : handleClose
  return (
    <Modal closeMethod={handleClose}>
      <div css={styles.body}>
        <img src={iconError} />
        <div css={styles.title}>Whoops!</div>
        <div css={styles.text}>
          {`Sorry, looks like something went wrong.
          Please try completing the action again.`}
        </div>
        <SubmitButton onClick={tryAgainClickHandler} width={270}>
          TRY AGAIN
        </SubmitButton>
      </div>
    </Modal>
  )
}
