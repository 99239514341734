import * as React from 'react'
import { capitalize } from './../../utils'
import * as styles from './item-card.styles'

export interface ItemCardProps {
  label: string
  valueList?: string[]
  subTextLabel?: string
  subTextValueList?: string[]
  displaySubtext?: boolean
  children?: React.ReactNode
}
export const ItemCard: React.FC<ItemCardProps> = (props) => {
  const {
    children,
    label,
    valueList,
    subTextLabel,
    subTextValueList,
    displaySubtext,
  } = props

  return (
    <div css={styles.itemCardWrapper}>
      <div css={valueList ? styles.itemCard : styles.itemCardWithChildren}>
        <div css={valueList ? styles.label : styles.labelWithChildren}>
          {label}
        </div>
        <div css={styles.valueBlock}>
          <div key={'spacer'} css={styles.spacer} />
          {valueList
            ? valueList.map((v, index) => {
                return (
                  <React.Fragment key={`value-chunk-${index}`}>
                    {index === 0 ? null : (
                      <div
                        key={`v-sep-${index}`}
                        css={styles.verticalSeparator}
                      />
                    )}
                    <div key={`${v}-${index}`} css={styles.value}>
                      {capitalize(v)}
                    </div>
                  </React.Fragment>
                )
              })
            : children}
        </div>
      </div>
      {displaySubtext && (
        <div css={styles.itemCardSubtextWrapper}>
          <div css={styles.itemCardSubtext}>
            <div css={styles.subtext}>
              <div css={styles.subtextLabel}>{subTextLabel}</div>
              <div css={styles.valueBlock}>
                <div key={'spacer'} css={styles.spacer} />
                {subTextValueList
                  ? subTextValueList.map((v, index) => {
                      return (
                        <React.Fragment key={`value-chunk-${index}`}>
                          {index === 0 ? null : (
                            <div
                              key={`v-sep-${index}`}
                              css={styles.verticalSeparator}
                            />
                          )}
                          <div key={`${v}-${index}`} css={styles.value}>
                            {v}
                          </div>
                        </React.Fragment>
                      )
                    })
                  : children}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
