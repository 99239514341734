import * as styles from './offer-selected.styles'
import {
  Card,
  ErrorModal,
  FooterNoteNode,
  Page,
  SubmitButton,
} from './../../components'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  acceptedOfferPostState,
  isPageLoadingState,
  loadSavedCalcState,
  pendingOfferState,
  requirementListState,
} from './../../state'
import {
  format$,
  formatValue,
  logColor,
  roundToNearestTenThousandth,
  scrollToTop,
} from './../../utils'
import { DEFAULT_HEADERS } from '@mulliganfunding/api-partner-portal'
import { PendingOfferStatus, POST_HEADERS_JSON } from './../../constants'
import React, { useEffect, useState } from 'react'
import { GoBackModal } from './go-back-modal'
import iconInfo from '../../assets/imgs/icon-info.svg'
import iconLeftChevron from '../../assets/imgs/left-chevron.svg'
import iconOfferSelected72x72 from '../../assets/imgs/offer-selected-72x72.svg'
import { ToolTip } from './../../components/tool-tip'

interface OfferDetail {
  label: string
  value: string
}

const OfferDetailsLineItem: React.FC<OfferDetail> = ({ label, value }) => {
  return (
    <div css={styles.offerDetailsLineItem}>
      <div css={styles.offerDetailsLabel}>{label.toUpperCase()}</div>
      <div css={styles.offerDetailsValue}>{value}</div>
    </div>
  )
}

export const OfferSelectedPage: React.FC = () => {
  const setIsLoading = useSetRecoilState(isPageLoadingState)

  const [isDisplayTooltip, setIsDisplayTooltip] = useState<boolean>(false)
  const [pendingOffer, setPendingOffer] = useRecoilState(pendingOfferState)
  const [isDisplayErrorModal, setIsDisplayErrorModal] = useState<boolean>(false)
  const acceptedOffer = useRecoilValue(acceptedOfferPostState)
  const [isDisplayGoBackModal, setIsDisplayGoBackModal] =
    useState<boolean>(false)
  const requirementList = useRecoilValue(requirementListState)
  const setLoadSavedCalc = useSetRecoilState(loadSavedCalcState)

  logColor.gray('offer selected: ', { acceptedOffer, requirementList })

  const handleGoBackModalLaunch = () => setIsDisplayGoBackModal(true)

  const handleGoBack = async () => {
    setIsLoading(true)
    // TODO modal to warn / confirm go back
    const response = await fetch(`/api/partner-portal/calculator/status`, {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify({ status: 'ACTIVE', uuid: pendingOffer.uuid }),
    })
    if (response.ok) {
      // go back, set status to active, accepted
      setPendingOffer({ ...pendingOffer, status: 'ACTIVE' })
      setLoadSavedCalc(true)
    } else {
      // error response
      logColor.red(
        'ERROR offer selecte go back attempt returned an error',
        response,
      )
      setIsDisplayGoBackModal(false)
      setIsDisplayErrorModal(true)
    }
    setIsLoading(false)
  }

  const handleCreateLoanClick = async () => {
    setIsLoading(true)
    // TODO add try / catch
    const status =
      PendingOfferStatus[
        PendingOfferStatus.ACCEPTED_SELECT_DOC_DELIVERY_PREFERENCE
      ]
    const response = await fetch(`/api/partner-portal/calculator/status`, {
      method: 'POST',
      headers: POST_HEADERS_JSON,
      body: JSON.stringify({
        status,
        uuid: pendingOffer.uuid,
      }),
    })
    if (response.ok) {
      // go back, set status to active, accepted
      setPendingOffer({ ...pendingOffer, status })
    } else {
      setIsDisplayErrorModal(true)
      // error response
      logColor.red('ERROR Offer Selected > Create click returned an error', {
        status,
        response,
      })
    }
    setIsLoading(false)
  }

  const modalCloseMethod = () => setIsDisplayGoBackModal(false)

  const ao = acceptedOffer
  const pt = acceptedOffer?.pricing_tier
  const round4 = roundToNearestTenThousandth

  useEffect(() => {
    scrollToTop()
  }, [])

  // display origination fee text/icon/tooltip if not empty/0
  let originationFeeTradedDisplay = null
  if (ao?.points_traded) {
    originationFeeTradedDisplay = (
      <>
        {' '}
        | {ao?.points_traded}%{` Origination Fee Traded `}
        <div
          css={styles.iconTooltipWrapper}
          onMouseOver={() => setIsDisplayTooltip(true)}
          onMouseOut={() => setIsDisplayTooltip(false)}
        >
          <img css={styles.iconInfo} src={iconInfo} />
          <ToolTip addCss={styles.addToTooltip} display={isDisplayTooltip}>
            Trading origination points results in an adjusted commision total
            and buy rate.
          </ToolTip>
        </div>
      </>
    )
  }

  return (
    <>
      {isDisplayGoBackModal && (
        <GoBackModal
          handleCancel={modalCloseMethod}
          handleGoBack={handleGoBack}
        />
      )}
      <ErrorModal
        isDisplay={isDisplayErrorModal}
        handleClose={() => setIsDisplayErrorModal(false)}
      />
      <Page
        title={'Offer Submission Calculator'}
        footerNoteNode={FooterNoteNode}
      >
        <div css={styles.goBackSection} onClick={handleGoBackModalLaunch}>
          <img
            css={styles.goBackChevronIcon}
            src={iconLeftChevron}
            alt={'go back icon'}
          />
          <div css={styles.goBackLink}> Back To Selected Offer</div>
        </div>
        <Card width="1140px">
          <div css={styles.bodyContentWrapper}>
            <div css={styles.offerSelected}>
              <img src={iconOfferSelected72x72} alt={`offer selected icon`} />
              <div css={styles.offerTitle}>Offer Selected</div>
              <div css={styles.offerText}>
                You have selected an offer for{' '}
                <span css={styles.semiBold}>{pendingOffer?.merchant_name}</span>
                .<br />
                Review the offer details below and proceed to create the loan
                agreement.
              </div>
            </div>
            <div css={styles.shadedBlockRepayment}>
              <div css={styles.offerDetailsWhiteBlock}>
                <div css={styles.totalRepayment}>TOTAL REPAYMENT</div>
                <div css={styles.totalRepaymentAmount}>
                  {format$(acceptedOffer?.total_repayment)}
                </div>
                <div css={styles.repaymentDetails}>
                  {round4(ao?.buy_rate)} Buy Rate | {round4(ao?.factor)} Factor
                </div>
                <div css={styles.repaymentDetails}>
                  {pt?.early_payoff_int_forgiveness}% Interest Forgiveness
                  {originationFeeTradedDisplay}
                </div>
              </div>
              <div css={styles.offerDetailsTriColumn}>
                <div css={styles.offerDetailsColumn}>
                  <OfferDetailsLineItem
                    label="LOAN AMOUNT"
                    value={format$(ao?.funding_amt)}
                  />
                  <OfferDetailsLineItem
                    label="NET FUNDING AMOUNT"
                    value={format$(ao?.loan_amt_net_origin)}
                  />
                </div>
                <div css={styles.offerDetailsColumn}>
                  <OfferDetailsLineItem
                    label="TERM"
                    value={ao?.term_months + ' months'}
                  />
                  <OfferDetailsLineItem
                    label="COMMISSION"
                    value={`${formatValue(
                      ao?.commission_percentage * 100,
                      '%',
                    )} | ${format$(ao?.commission_dollar_amt)}`}
                  />
                </div>
                <div css={styles.offerDetailsColumn}>
                  <OfferDetailsLineItem
                    label="PAYMENT"
                    value={`${format$(acceptedOffer?.payment, 2)} ${
                      ao?.frequency
                    }`}
                  />
                  <OfferDetailsLineItem
                    label="ORIGINATION FEE"
                    value={`${formatValue(
                      acceptedOffer?.orig_fee_percent,
                      '%',
                    )} | ${format$(ao?.orig_fee_dollars)}`}
                  />
                </div>
              </div>
            </div>
            <div css={styles.shadedBlockReqToFund}>
              <div css={styles.reqToFundTitle}>
                Requirements to Fund (
                {Array.isArray(requirementList) ? requirementList.length : 0})
              </div>
              {Array.isArray(requirementList) &&
                requirementList.map((req, idx) => (
                  <div
                    css={styles.reqToFundReqline}
                    key={`req-to-fund-line-${idx}`}
                  >
                    <span css={styles.bullet}>●</span> {req}
                  </div>
                ))}
              <div css={styles.reqNote1}>
                Additional documentation could be required<sup>1</sup>
              </div>
            </div>
            <SubmitButton paddingLR={35} onClick={handleCreateLoanClick}>
              Continue
            </SubmitButton>
          </div>
        </Card>
      </Page>
    </>
  )
}
