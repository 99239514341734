import { ContactInfo } from '../models'
import { PricingTier, PricingType } from '@mulliganfunding/api-partner-portal'

export const DEFAULT_MERCHANT_INFO = {
  name: `Sinclair Moving & Storage, Inc`,
  offerType: `Regular`,
  tier: { pricing_type: PricingType.Premier100 } as PricingTier,
  loanSpecialist: {
    name: `Cody Hattox`,
    phone: `760-555-1212`,
  } as ContactInfo,
}
