import { css } from '@emotion/react'
import { cssText, mfThemeVar } from '@mulliganfunding/fe-style'

export const buttonWrapper = css`
  background-color: var(${mfThemeVar.PRIMARY});
  color: var(${mfThemeVar.TEXT_DEFAULT_ON_DARK});
  ${cssText.fontWeight._SEMI_BOLD}
  ${cssText.letterSpacing._pos1p25}
  ${cssText.fontSize._14px}
  padding: 0 10px;
  text-align: center;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`

export const submit = css`
  margin-bottom: 30px;
`
