import { InputMode } from './../../constants'
import React, { useEffect, useState } from 'react'
import * as styles from './input-display-box.styles'
import { isEmailValid } from './../../utils'

type InputOrDisplayBoxProps = {
  value: string
  onChange?: (e) => void
  mode: InputMode
  isSubmitPressed: boolean
}

export const InputOrDisplayBox: React.FC<InputOrDisplayBoxProps> = ({
  value,
  mode,
  onChange,
  isSubmitPressed,
}) => {
  const [isEmailBlurred, setIsEmailBlurred] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (isSubmitPressed || isEmailBlurred) {
      const newIsError = !isEmailValid(value)
      if (isError !== newIsError) {
        setIsError(newIsError)
      }
    }
  }, [isEmailBlurred, isSubmitPressed, value])

  if (mode === InputMode.display) {
    return <div css={styles.displayBox}>{value}</div>
  }
  if (mode === InputMode.display_bold) {
    return <div css={styles.displayBoldBox}>{value}</div>
  }
  if (mode === InputMode.input) {
    return (
      <div css={styles.inputErrorWrapper}>
        <input
          css={isError ? styles.textInputError : styles.textInput}
          type={'text'}
          value={value}
          onChange={onChange}
          onBlur={() => setIsEmailBlurred(true)}
        />
        <div css={isError ? styles.inputErrorMessage : styles.hideMe}>
          Please enter an email address
        </div>
      </div>
    )
  }
}
