import * as React from 'react'
import * as styles from './page-loader.styles'
import uploading from '../../assets/imgs/uploading.svg'

interface PageLoaderProps {
  display?: boolean
}

export const PageLoader: React.FC<PageLoaderProps> = (props) => {
  const { display } = props

  return display ? (
    <div css={styles.container}>
      <div css={styles.spinnerFrame}>
        <img
          css={styles.rotate}
          src={uploading}
          alt={`loading icon`}
          width="100"
          height="100"
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
