import React from 'react'
import * as styles from './modal.styles'
import closeModalIcon from './../../assets/imgs/close-modal-icon.svg'

type Props = {
  children: React.ReactNode
  closeMethod: () => void
}

export const Modal: React.FC<Props> = ({ closeMethod, children }) => {
  const handleCloseClick = (e) => {
    e.stopPropagation()
    closeMethod()
  }

  const preventBubbling = (e) => {
    e.stopPropagation()
  }

  return (
    <>
      <div css={styles.overlay} onClick={handleCloseClick}></div>
      <div css={styles.contentWrapper} onClick={preventBubbling}>
        <div css={styles.accentLine}></div>
        <div css={styles.header}>
          <img
            src={closeModalIcon}
            css={styles.xClose}
            onClick={handleCloseClick}
          />
        </div>
        {children}
      </div>
    </>
  )
}
