import { css } from '@emotion/react'
import { cssTyp, mfThemeVar } from '@mulliganfunding/fe-style'
import { CIRCLE_CHECKMARK, EMPTY_CIRCLE, LOCK_ICON } from '../../constants/images.js'

export const checkboxContainer = css`
  label: checkbox-container;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px;
  padding-left: 10px;
  box-sizing: border-box;
  height: 42px;
  width: 240px;
  vertical-align: middle;
  border: 1px solid var(${mfThemeVar.TEXT_DEFAULT});
  background-color: var(${mfThemeVar.TEXT_DEFAULT_ON_DARK});

  input {
    float: right;
  }
`
export const checkboxContainerActive = css`
  label: checkbox-container-active;
  ${checkboxContainer}
  border: 2px solid var(${mfThemeVar.PRIMARY});

  input {
    &:checked {
      background-color: var(${mfThemeVar.TEXT_DEFAULT_ON_DARK});
    }
  }
`

export const checkmarkLabel = css`
  label: checkmark-label;
  flex: 1;
  text-align: left;
  text-transform: uppercase;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  ${cssTyp._BODY_SMALL_SUBTITLE}
  font-weight: 600;
  vertical-align: middle;
  cursor: pointer;
`
export const checkmarkRadioButton = css`
  label: checkmark-radio-button;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-width: 0;
  :after {
    width: 24px;
    height: 24px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    background: url(${EMPTY_CIRCLE}) center no-repeat scroll;
  }
  :checked:after {
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
    visibility: visible;
    background: url(${CIRCLE_CHECKMARK}) center no-repeat scroll;
  }
`

export const lockIconDiv = css`
  label: lock-icon-div;
  border-width: 0;
  float: right;
  width: 24px;
  height: 24px;
  background: url(${LOCK_ICON}) center no-repeat scroll;
`
