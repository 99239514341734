import { DEFAULT_LOCALE } from './../constants'

export const format$ = (
  value: number | string,
  decimals = 0,
  dollarSign = '$',
) => {
  if (!value) return dollarSign + '0'
  const numVal = typeof value === 'string' ? Number(value) : value
  const formatted = numVal.toLocaleString(DEFAULT_LOCALE, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
  return dollarSign + formatted
}
