import * as React from 'react'
import * as styles from './page.styles'
import headerLogo from '../../assets/imgs/MF_Horizontal_RGB.svg'
import { css } from '@emotion/react'

import { PageLoader } from '../../components'
import { isPageLoadingState } from '../../state'
import { useRecoilValue } from 'recoil'

interface PageProps {
  title?: string
  widthPx?: number
  footerNoteNode?: React.ReactNode | React.ReactNode[]
  children: React.ReactNode
}

export const Page: React.FC<PageProps> = (props) => {
  const { children, title, widthPx, footerNoteNode } = props
  const isLoading = useRecoilValue(isPageLoadingState)

  let styleHeaderCentered = styles.headerCentered
  if (widthPx) {
    styleHeaderCentered = css(styles.headerCentered, `width: ${widthPx}px; `)
  }
  return (
    <div css={styles.pageWrapper}>
      <div css={styles.headerBar}>
        <div css={styleHeaderCentered}>
          <img
            css={styles.headerIcon}
            src={headerLogo}
            alt={'Mulligan Funding Logo'}
          />
          {!title ? null : (
            <>
              <div css={styles.headerDivider}>&nbsp;</div>
              <h1 css={styles.title}>{title}</h1>
            </>
          )}
        </div>
      </div>

      <div css={styles.page}>
        <div css={styles.body}>
          <PageLoader display={isLoading} />
          {children}
        </div>
        <div css={styles.footer}>
          {/* footerNoteNode is for displaying footnotes above standard legalese */}
          {footerNoteNode && (
            <div
              css={css(
                `label: footer-note-wrapper; width: ${
                  widthPx || 1140
                }px; margin-bottom: 20px;`,
              )}
            >
              {footerNoteNode}
            </div>
          )}
          <div css={styles.footerText}>
            {`© ${new Date().getFullYear()} Mulligan Funding. All Rights Reserved.`}
          </div>
        </div>
      </div>
    </div>
  )
}
