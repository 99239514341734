import { css } from '@emotion/react'
import { mfThemeVar } from '@mulliganfunding/fe-style'

export const show = css`
  display: block;
`

export const hide = css`
  display: none;
`

export const contentWrapper = css`
  label: modal-content-wrapper;
  position: fixed;
  width: 570px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1010;
  background-color: white;
`

export const overlay = css`
  label: overlay;
  opacity: 0.4;
  display: block;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(${mfThemeVar.NEUTRAL_95});
`

export const header = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const accentLine = css`
  height: 6px;
  background-color: var(${mfThemeVar.SECONDARY_LIGHT});
  width: 100%;
`

export const xClose = css`
  label: x-close;
  height: 24px;
  width: 24px;
  margin: 20px;
  cursor: pointer;
  opacity: 0.5;
`
