import * as React from 'react'
import * as styles from './information-section.styles'
import infoAlertLinkIcon from '../../assets/imgs/info-alert-link-icon.svg'

export interface InformationSectionProps {
  text: string
}
export const InformationSection: React.FC<InformationSectionProps> = (
  props,
) => {
  const { text } = props
  return (
    <div css={styles.info}>
      <img css={styles.infoIcon} src={infoAlertLinkIcon} alt={`info icon`} />
      <div>{text}</div>
    </div>
  )
}
