import { css } from '@emotion/react'
import { mfThemeVar } from '@mulliganfunding/fe-style'
import * as React from 'react'
import * as styles from './percentage-tool-tip.styles'

export interface PercentageToolTipProps {
  percent: number
  position?: number
  display: boolean
}
export const PercentageToolTip: React.FC<PercentageToolTipProps> = (props) => {
  const { percent, position, display } = props

  const toolTipStyle = css({
    label: 'tool-tip',
    left: '220px',
    bottom: '40px',
    display: display ? 'inline-block' : 'none',
    position: 'relative',
    border: `1px solid var(${mfThemeVar.PRIMARY})`,
    background: 'rgba(255,255,255)',
    width: '240px',
    borderRadius: '2px',
    boxShadow: '0 2px 14px 0 rgba(0,0,0,0.14)',
    marginBottom: '15px',
    textAlign: 'center',
  })

  return (
    <div css={css('position: relative; height: 0px; width: 0px;')}>
      <div css={toolTipStyle}>
        <p css={styles.tooltipText}>
          <span css={styles.tooltipPercent}>{percent}%</span>
          <span css={styles.tooltipTextISO}>Origination Fee Traded </span>
        </p>
      </div>
    </div>
  )
}
