import * as React from 'react'
import { ChangeEventHandler, useMemo } from 'react'
import * as styles from './multi-thumb-slider.styles'

export interface MultiThumbSliderProps {
  min: number
  max: number
  value: number
  step: number
  subSliderValue: number
  onChange: ChangeEventHandler<HTMLInputElement>
}
export const MultiThumbSlider: React.FC<MultiThumbSliderProps> = (props) => {
  const { min, max, value, step, subSliderValue, onChange } = props

  const mainSliderPercentage = useMemo(() => {
    return Math.trunc((100 * (value - min)) / (max - min))
  }, [min, max, value])

  const subSliderPercentage = useMemo(() => {
    return Math.trunc((100 * (subSliderValue - min)) / (max - min))
  }, [min, max, subSliderValue])

  return (
    <div css={styles.container}>
      <div css={styles.slider}>
        <input
          css={styles.subRangeSlider(subSliderPercentage)}
          type="range"
          min={min}
          max={max}
          value={subSliderValue}
          step={step}
          onChange={() => null}
        />
        <input
          css={styles.mainRangeSlider(mainSliderPercentage)}
          type="range"
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={onChange}
        />

        <div css={styles.sliderDefaultTrack}></div>
      </div>
    </div>
  )
}
