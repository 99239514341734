import { css } from '@emotion/react'
import { cssText, mfThemeVar } from '@mulliganfunding/fe-style'
import { fontWeight } from '@mulliganfunding/fe-style'
import { defFont } from './../../utils'

export const bodyContentWrapper = css`
  label: body-content-wrapper;
  display: flex;
  padding: 30px 0 60px 0;
  flex-direction: column;
  align-items: center;
`

export const iconTooltipWrapper = css`
  label: icon-tooltip-wrapper;
  position: absolute;
  display: inline-block;
  height: 0;
  width: 0;
`

export const offerSelected = css`
  label: offer-selected;
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  margin-bottom: 30px;
`

export const offerTitle = css`
  label: offer-title;
  font-size: 32px;
  ${fontWeight._MEDIUM}
  color: var(${mfThemeVar.TEXT_DEFAULT});
  margin-top: 24px;
`
export const offerText = css`
  label: offer-text;
  line-height: 24px;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  ${fontWeight._REGULAR}
  font-size: 16px;
  text-align: center;
  margin-top: 25px;
`

export const goBackSection = css`
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
`

export const goBackLink = css`
  color: var(${mfThemeVar.LINK});
  font-size: 16px;
  ${fontWeight._MEDIUM}
`
export const goBackChevronIcon = css`
  margin-right: 10px;
`

export const shadedBlockRepayment = css`
  label: shaded-block;
  width: 750px;
  text-align: center;
  font-family: var(${mfThemeVar.FONT_FAMILY});
  ${fontWeight._SEMI_BOLD}
  color: var(${mfThemeVar.TEXT_DEFAULT});
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background-color: var(${mfThemeVar.NEUTRAL_25});
`

export const offerDetailsWhiteBlock = css`
  label: offer-details-white-block;
  background-color: var(${mfThemeVar.NEUTRAL_0});
  margin: 25px 25px 0 25px;
  box-sizing: border-box;
  width: calc(100% - 50px);
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 23px;
`

export const totalRepayment = defFont('b', 14, 20, 1.25, mfThemeVar.NEUTRAL_80)
export const totalRepaymentAmount = css(
  'label: total-repayment-amount;',
  defFont('sb', 44, 54, -0.3, mfThemeVar.TEXT_DEFAULT),
  'margin-top: 5px; margin-bottom: 7px;',
)

export const repaymentDetails = css(
  'label: repayment-details;',
  defFont('m', 16, 26, 0.3, mfThemeVar.NEUTRAL_80),
  'position: relative; margin-top: 2px;',
)

export const iconInfo = css`
  label: icon-info;
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 4px;
`

export const addToTooltip = css`
  position: relative;
  display: inline-block;
  bottom: 105px;
  left: -130px;
  marginbottom: -37px;
`

export const offerDetailsTriColumn = css`
  label: offer-details-tri-column;
  display: flex;
  padding: 23px 10px;
  margin-bottom: -2px;
  width: 100%;
  box-sizing: border-box;
`

export const offerDetailsColumn = css`
  label: offer-details-column;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

export const offerDetailsLineItem = css`
  label: offer-details-line-item;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
`

export const offerDetailsLabel = css`
  label: offer-details-label;
  ${fontWeight._BOLD}
  ${cssText.fontSize._14px}
  color: #454646;
  margin-bottom: 6px;
`

export const offerDetailsValue = css`
  label: offer-details-value;
  ${fontWeight._SEMI_BOLD}
  ${cssText.fontSize._16px}
    color: var(${mfThemeVar.TEXT_DEFAULT});
`

export const shadedBlockReqToFund = css`
  label: shaded-block;
  width: 750px;
  margin-bottom: 40px;
  background-color: var(${mfThemeVar.NEUTRAL_25});
  padding: 25px;
  box-sizing: border-box;
`

export const bullet = css`
  label: req-to-fund-bullet;
  position: relative;
  bottom: 1px;
`

export const reqToFundTitle = css(
  'label: req-to-fund-title;',
  defFont('sb', 16, 24, 0.3, mfThemeVar.TEXT_DEFAULT),
  'margin-bottom: 13px;',
)

export const reqToFundReqline = css`
  label: req-to-fund-req-line;
  margin-left: 2em;
  text-indent: -2em;
  margin-bottom: 4px;
`

export const reqNote1 = css(
  'label: req-note-1;',
  'margin-top: 11px;',
  'font-style: italic;',
)

export const semiBold = css(cssText.fontWeight._SEMI_BOLD)
