// The constants in this file are named based on the actual image name

// The IMAGE_ROOT coresponds to the folder inside the public folder at the base level of the project
const IMAGE_ROOT = '/imgs'
export const AD_BIGGERDEALS = `${IMAGE_ROOT}/ad-biggerdeals-1140x86.gif`
export const CHEVRON_DOWN = `${IMAGE_ROOT}/chevron-down.svg`
export const CHEVRON_UP = `${IMAGE_ROOT}/chevron-up.svg`
export const CIRCLE_CHECKMARK = `${IMAGE_ROOT}/circle-checkmark.svg`
export const CIRCLE_UP_ARROW = `${IMAGE_ROOT}/circle-up-arrow.svg`
export const CLOSE_MODAL_ICON = `${IMAGE_ROOT}/close-modal-icon.svg`
export const EMPTY_CIRCLE = `${IMAGE_ROOT}/empty-circle.svg`
export const GO_BACK_MODAL_ICON = `${IMAGE_ROOT}/go-back-modal-icon.svg`
export const ICON_CHECKMARK = `${IMAGE_ROOT}/icon-checkmark.svg`
export const ICON_ERROR = `${IMAGE_ROOT}/icon-error.svg`
export const ICON_INFO = `${IMAGE_ROOT}/icon-info.svg`
export const ICON_LOAN_AGREEMENT = `${IMAGE_ROOT}/icon-loan-agreement.svg`
export const ICON_OFFER_LOCKED = `${IMAGE_ROOT}/icon-offer-locked.svg`
export const INFO_ALERT_LINK_ICON = `${IMAGE_ROOT}/info-alert-link-icon.svg`
export const LEFT_CHEVRON = `${IMAGE_ROOT}/left-chevron.svg`
export const LOCK_ICON = `${IMAGE_ROOT}/lock-icon.svg`
export const MF_HORIZONTAL_RGB = `${IMAGE_ROOT}/MF_HORIZONTAL_RGB.svg`
export const OFFER_SELECTED_72X72 = `${IMAGE_ROOT}/offer-selected-72x72.svg`
export const OFFER_SELECTED = `${IMAGE_ROOT}/offer-selected.svg`
export const ORANGE_INFO_ICON = `${IMAGE_ROOT}/orange-info-icon.svg`
export const PATTERN_BACKGROUND_DARK = `${IMAGE_ROOT}/pattern-background-dark.svg`
export const PATTERN_ORANGEBAR = `${IMAGE_ROOT}/pattern-orangebar.svg`
export const PHONE_LINK_ICON = `${IMAGE_ROOT}/phone-link-icon.svg`
export const RADIO_BUTTON_DEFAULT_ICON = `${IMAGE_ROOT}/radio-button-default-icon.svg`
export const RADIO_BUTTON_SELECTED_ICON = `${IMAGE_ROOT}/radio-button-selected-icon.svg`
export const REDO_ICON_DISABLED = `${IMAGE_ROOT}/redo-icon-disabled.svg`
export const STAR_ORANGE_ICON = `${IMAGE_ROOT}/star-orange-icon.svg`
export const UPLOADING = `${IMAGE_ROOT}/uploading.svg`
