import { css, keyframes } from '@emotion/react'

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`
export const rotate = css`
  animation: ${rotation} 2s infinite linear;
`
export const spinnerFrame = css`
  label: spinner-frame;
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  padding: 10px;
`
export const container = css`
  label: page-container;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  text-align: center;
  background: rgba(238, 238, 238, 0.75);
  z-index: 2000;

  &:before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
`
