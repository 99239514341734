import React from 'react'
import { Modal, SubmitButton } from './../../components'
import * as styles from './go-back-modal.styles'
import goBackModalIcon from './../../assets/imgs/go-back-modal-icon.svg'

type Props = {
  handleCancel: () => void
  handleGoBack: () => void
}

export const GoBackModal: React.FC<Props> = ({
  handleCancel,
  handleGoBack,
}) => {
  return (
    <Modal closeMethod={handleCancel}>
      <div css={styles.body}>
        <img src={goBackModalIcon} />
        <div css={styles.title}>Before you go back...</div>
        <div css={styles.text}>
          By going back to the previous screen, all your previous offers will be
          reopened. Would you like to go back?
        </div>
        <SubmitButton onClick={handleGoBack} width={270}>
          YES, GO BACK
        </SubmitButton>
        <div css={styles.cancelLink} onClick={handleCancel}>
          Cancel
        </div>
      </div>
    </Modal>
  )
}
