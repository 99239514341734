import React from 'react'
import { Page } from '../../components'
import * as styles from './about.styles'
import * as packageInfo from '../../../package.json'

const { name, version } = packageInfo
export const About: React.FC = () => {
  return (
    <Page title={'About Partner Portal'}>
      <div css={styles.singleLine}>
        <label css={styles.text} htmlFor="appName">
          {`Name:`}
        </label>
        <input
          css={styles.input}
          type="text"
          readOnly={true}
          id="appName"
          value={name}
        />
      </div>
      <div css={styles.singleLine}>
        <label css={styles.text} htmlFor="appVersion">
          {`Version:  `}
        </label>
        <input
          css={styles.input}
          type="text"
          readOnly={true}
          id="appVersion"
          value={version}
        />
      </div>
    </Page>
  )
}
