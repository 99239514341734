import * as Sentry from '@sentry/react'
import { FallbackRender } from '@sentry/react'
import React from 'react'

export interface SentryComponentProps {
  fallback?: React.ReactElement | FallbackRender

  children?: React.ReactNode
}

export const SentryComponent: React.FC<SentryComponentProps> = (props) => {
  const { children, fallback } = props
  return (
    <>
      <Sentry.ErrorBoundary
        fallback={fallback ? fallback : <p>An error has occurred</p>}
      >
        {children}
      </Sentry.ErrorBoundary>
    </>
  )
}
