// path segments
const SEG_ABOUT = 'about'
// When changing from /calc to /calculator, just update ROOT_FOLDER
const ROOT_FOLDER = 'calculator'
const SEG_CALC = `${ROOT_FOLDER}/:calcUuid`
const SEG_CALC_OFFER_SELECTED = `${ROOT_FOLDER}/offer-selected/:calcUuid`
const SEG_CALC_CREATE_LOAN_AGREEMENT = `${ROOT_FOLDER}/create-loan-agreement/:calcUuid`
const SEG_CALC_CREATE_RESULT = `${ROOT_FOLDER}/loan-agreement-result/:calcUuid`

// path prefix for local-dev
export const PATH_PREFIX = 'ui/partner-portal/'

export const PATH_ABOUT = `${PATH_PREFIX}${SEG_ABOUT}`
export const PATH_ABOUT_ROOT = `/${SEG_ABOUT}`

export const PATH_ISO_CALC = `${PATH_PREFIX}${SEG_CALC}`
export const PATH_ISO_CALC_ROOT = `/${SEG_CALC}`

export const PATH_OFFER_SELECTED = `${PATH_PREFIX}${SEG_CALC_OFFER_SELECTED}`
export const PATH_OFFER_SELECTED_ROOT = `/${SEG_CALC_OFFER_SELECTED}`
export const PATH_CREATE_LOAN_AGREEMENT = `${PATH_PREFIX}${SEG_CALC_CREATE_LOAN_AGREEMENT}`
export const PATH_CREATE_LOAN_AGREEMENT_ROOT = `/${SEG_CALC_CREATE_LOAN_AGREEMENT}`
export const PATH_CREATE_RESULT = `${PATH_PREFIX}${SEG_CALC_CREATE_RESULT}`
export const PATH_CREATE_RESULT_ROOT = `/${SEG_CALC_CREATE_RESULT}`
