import * as React from 'react'
import * as styles from './checkbox-button.styles'

export interface CheckboxButtonProps {
  id: string
  label: string
  name: string
  isSelected: boolean
  isLocked: boolean
  handleChange: (newValue: string) => void
}
export const CheckboxButton: React.FC<CheckboxButtonProps> = (props) => {
  const { label, id, name, isSelected, isLocked, handleChange } = props
  return (
    <div
      css={
        isSelected ? styles.checkboxContainerActive : styles.checkboxContainer
      }
      onClick={() => handleChange(id)}
    >
      <label css={styles.checkmarkLabel}>{label}</label>

      <input
        id={id}
        name={name}
        type="radio"
        value={id}
        checked={isSelected}
        css={styles.checkmarkRadioButton}
        onChange={() => null}
      />
    </div>
  )
}
