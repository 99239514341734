import { DEFAULT_HEADERS } from '@mulliganfunding/api-partner-portal'

// numbers
export const ONE_HUNDRED_FIFTY_THOUSAND = 150_000
export const DAYS_IN_WEEK = 5

export const WEEKLY_STR = 'weekly'
export const LOCALE_EN_US = 'en-US'
export const DEFAULT_LOCALE = LOCALE_EN_US

export const DEFAULT_REQUIREMENTS_TO_FUND = [
  'Signed Loan Agreement',
  `Driver's License`,
  `Voided Check`,
  'MTD Banking Transations',
]

export const DEFAULT_ADDED_REQUIREMENTS = [
  `Additional Condition 1`,
  `Additional Condition 2`,
  `Additional Condition 3`,
]

// JSON_HEADERS
export const POST_HEADERS_JSON = {
  ...DEFAULT_HEADERS,
  accept: 'application/json',
}
