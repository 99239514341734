import { css } from '@emotion/react'
import { paddedBlock } from '../../constants'

export const card = css`
  ${paddedBlock}
  width: 100%;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 13px 14px 0 rgba(19, 72, 89, 0.04);
  margin-top: 10px;
`

export const cardTitle = css`
  color: black;
`
