import { css } from '@emotion/react'
import { mfThemeVar } from '@mulliganfunding/fe-style'
import { defFont } from './../../utils'

export const wrapper = css`
  label: message-page-wrapper;
  text-align: center;
`

export const title = defFont('m', 32, 42, -0.3, mfThemeVar.TEXT_DEFAULT)

const fontText = defFont('r', 16, 24, 0.3, mfThemeVar.TEXT_DEFAULT)

export const text = css(
  `label: message-page-text;`,
  fontText,
  'width: 720px;',
  'margin: 30px auto 260px auto;',
)

export const link = css(`color: var(${mfThemeVar.LINK})`)
