import { SerializedStyles } from '@emotion/react'
import * as styles from './submit-button.styles'
import { css } from '@emotion/react'

type Props = {
  onClick: () => void
  width?: number | string
  paddingLR?: number | string
  addCss?: SerializedStyles | string
  children: React.ReactNode
}

const pxString = (val: string | number) => {
  let strVal
  if (typeof val === 'number') {
    strVal = val + 'px'
  } else {
    if (!val.includes('px')) {
      strVal = val + 'px'
    }
  }
  return strVal
}

export const SubmitButton: React.FC<Props> = ({
  onClick,
  width,
  paddingLR,
  addCss,
  children,
}) => {
  const addStyles: (string | SerializedStyles)[] = []
  if (width) {
    addStyles.push(`width: ${pxString(width)};`)
  }
  if (paddingLR) {
    const padPx = pxString(paddingLR)
    addStyles.push(`padding-left: ${padPx}; padding-right: ${padPx};`)
  }
  if (addCss) {
    addStyles.push(addCss)
  }
  return (
    <div css={css(styles.buttonWrapper, ...addStyles)} onClick={onClick}>
      {children || 'SUBMIT'}
    </div>
  )
}
