import { atom, RecoilState } from 'recoil'
import * as Calc from '@mulliganfunding/loan-calculator'
import { FUNDING_AMT_MIN, WEEKLY_STR } from './../constants'

export const stateCalcNums: RecoilState<Calc.IBasicCalcData> = atom({
  key: 'calc-nums',
  default: {
    freeze_amt: 'no',
    payment_freq: WEEKLY_STR,
    channel: 'ISO',
    pricing_term_name: 'sample-tier',
    pricing_term_id: 'sample-tier-id',
    offer_exceptions: '',
    isNotDirect: true,
    hero_eligible: false,
    waive_refi_fee: false,
    max_markup_capped: false,
    funding_amt: 0,
    availableBalance: 0,
    monthlySales: 0,
    buy_rate: 0,
    max_markup: 0,
    total_factor: 0,
    sixmo_equiv_factor: 0,
    monthly_holdback_perc: 0,
    orig_fee_dollars: 0,
    orig_fee_percent: 0,
    total_repayment_amt: 0,
    daily_pmt: 0,
    monthly_pmt: 0,
    weekly_pmt: 0,
    payment_amt: 0,
    max_payment_daily: 0,
    commission: 0,
    selectedFundingAmount: FUNDING_AMT_MIN,
    selectedTotalMonths: 0,
    selectedCommissionPercent: 0,
    merchantName: '',
    relationshipManager: '',
    offerType: 'Regular',
    tier: 'Standard',
    uuid: '',
    term_days: 0,
    term_months: 0,
  },
})
