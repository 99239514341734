export const stripFormatting: (formattedNumber: string) => number = (
  formattedNumber,
) => {
  return Number(
    formattedNumber
      .replace(/[^\d.-]/g, '') // remove chars except number, hyphen, point.
      .replace(/(\..*)\./g, '$1') // remove multiple points.
      .replace(/(?!^)-/g, ''), // remove middle hyphen.
  )
}
