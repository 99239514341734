import { PendingOfferStatus, PendingOfferTemp } from './../constants'
import { logColor } from './log-color'

const logWarn = (
  msg: string,
  obj: any[] | { [key: string]: any } | null = null,
) => {
  const addedInfo = obj ? JSON.stringify(obj) : ''
  logColor.yellow('LOADER WARNING: PendingOffer - ' + msg + addedInfo)
}

export const getPendingOfferErrors = (pendingOffer: PendingOfferTemp) => {
  console.log('getPendingOfferErrors')
  const status = pendingOffer?.status
  const errors = []

  const addError = (label: string, value: string | any = null) => {
    if (value && typeof value !== 'string') {
      value = JSON.stringify(value)
    }
    errors.push(label + ': ' + value)
  }

  if (!pendingOffer) {
    addError('pendingOffer is missing: ', pendingOffer)
    return errors
  }

  // check that status is legit
  if (!PendingOfferStatus[status]) {
    addError('Invalid PendingOfferStatus: ' + PendingOfferStatus[status])
  }

  // if the PendingOfferStatus is not one that does not require a pricing_tier prop AND
  // the pricing_tier prop is missing ... THEN error message AND exit/return
  if (
    ![
      PendingOfferStatus.INACTIVE,
      PendingOfferStatus.COMPLETED,
      PendingOfferStatus.INACTIVE_ALL,
    ].includes(PendingOfferStatus[status]) &&
    !pendingOffer.pricing_tier
  ) {
    // to my knowledge this has never happened
    addError(
      `pendingOffer status: ${status} missing pricing_tier:`,
      pendingOffer.pricing_tier,
    )
    return errors
  }

  // pendingOffer should have a priing_tier object with property active === true
  const pricingTierActive = pendingOffer?.pricing_tier?.active
  if (!pricingTierActive) {
    logWarn(
      'PendingOffer.pricing_tier.active should equal true, instead is: ' +
        pricingTierActive,
    )
  }

  // if the default tier is an elite offer with months < 9 it's probably broken
  const pt = pendingOffer?.pricing_tier
  if (
    pt?.tier_basic_name &&
    pt?.tier_basic_name.toLowerCase() === 'elite' &&
    pt?.months < 9
  ) {
    logWarn(
      'WARNING default pricing_tier Elite should not be created with less than 9 months, has: ' +
        pt?.months,
    )
  }

  const offerStatusThatNeedsFurtherChecks = [
    PendingOfferStatus.ACTIVE,
    PendingOfferStatus.PROVISIONAL_ACCEPTED,
    PendingOfferStatus.ACCEPTED_SELECT_DOC_DELIVERY_PREFERENCE,
  ]

  if (!offerStatusThatNeedsFurtherChecks.includes(PendingOfferStatus[status])) {
    return errors.length ? errors : false
  }

  // check for pricing_tiers_list_v2 object
  const tiersV2 = pendingOffer.pricing_tiers_list_v2

  // pricing_tier_list_v2 exists and is not an empty object
  if (!tiersV2 || typeof tiersV2 !== 'object' || !Object.keys(tiersV2).length) {
    addError(
      'pendingOffer.pricing_tiers_list_v2 missing data',
      pendingOffer?.pricing_tiers_list_v2,
    )
    // no need to check further
    return errors
  }

  // check for valid tier names
  const tierNameProps = Object.keys(tiersV2)
  const validTierNameProps = ['standard', 'elite', 'premier', 'starter']
  const invalidTierNames = tierNameProps.filter(
    (val) => !validTierNameProps.includes(val.toLowerCase()),
  )
  if (invalidTierNames.length) {
    logWarn(`Unknown or new Tier Name Propertie(s)`, invalidTierNames)
  }

  tierNameProps.forEach((tierNameProp) => {
    const ifProps = Object.keys(tiersV2[tierNameProp].interest_forgiveness)
    // interest forgiveness (if) should be 50 or 100
    const invalidIfProps = ifProps.filter(
      (ifProp) => !['50', '100'].includes(ifProp),
    )
    if (invalidIfProps.length) {
      addError(
        `[${tierNameProp}] Unknown Interest Forgiveness Prop(s)`,
        invalidIfProps,
      )
    }

    // tier pricing_type should not have spaces
    ifProps.forEach((ifProp) => {
      const cohortObj = tiersV2[tierNameProp].interest_forgiveness[ifProp]
      const cohortMonths = Object.keys(cohortObj)
      cohortMonths.forEach((month) => {
        const tierObj = cohortObj[month]
        if (!tierObj?.active) {
          logWarn('cohort.active should be true, was not for: ', {
            tier: tierNameProp,
            interest_forgiveness: ifProp,
            month,
          })
        }
      })
    })

    // LOG WARNING IF GAURANTORS IS empty
    if (!pendingOffer.guarantors) {
      // TODO eventually this is probably a blocking error
      // but some pendingoffers in dev are usefull with
      // guarantors lacking for now.
      logWarn('GUARANTORS property is empty' + pendingOffer.guarantors)
    }
  })

  if (errors.length) {
    return errors
  }

  return false
}
