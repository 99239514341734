import { css } from '@emotion/react'
import { mfThemeVar } from '@mulliganfunding/fe-style'
import { defFont } from './../../utils'

export const whiteBlock = css`
  background-color: var(${mfThemeVar.NEUTRAL_0});
  width: 100%;
  margin-top: 25px;
  padding: 35px 33px;
  box-sizing: border-box;
`

export const label = defFont('r', 14, null, 0.3, mfThemeVar.TEXT_DEFAULT)
export const displayBox = css`
  background-color: var(${mfThemeVar.NEUTRAL_15});
  padding: 10px;
  box-sizing: border-box;
`
