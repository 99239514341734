import { init } from '@sentry/react'
import React, { useEffect } from 'react'
import { getEnv } from './../../utils'

export interface SentryAppProps {
  appId: string
  customerId: string
  subDomain: string
  children?: React.ReactNode
}

export const SentryApp: React.FC<SentryAppProps> = (props) => {
  const { appId, children, customerId, subDomain } = props

  useEffect(() => {
    init({
      environment: getEnv(),
      allowUrls: [
        // `https://local-dev`,
        `https://${subDomain}.dev.mulligancloud.com`,
        `https://${subDomain}.stg.mulligancloud.com`,
        `https://${subDomain}.prod.mulligancloud.com`,
        `https://${subDomain}.mulliganfunding.com`,
      ],
      dsn: `https://${customerId}.ingest.sentry.io/${appId}`,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })
  }, [appId, customerId, subDomain])
  return <>{children}</>
}
