import {
  AcceptedOfferPost,
  PendingOffer,
  PricingTier,
} from '@mulliganfunding/api-partner-portal'

// TODO these should eventually be moved to api-partner-portal

// Accepted Offer on pending_offer.accepted_offer
export interface AcceptedOffer
  extends Omit<AcceptedOfferPost, 'pending_offer'> {
  sf_pricing_tier_id: string
  pricing_tier?: PricingTier
  points_traded: number
}

// AcceptedOfferSelect - payload sent to "select" endpoint
export interface AcceptedOfferSelect
  extends Omit<AcceptedOfferPost, 'pending_offer'> {
  points_traded: number
  sf_pricing_tier_id: string
}

export interface Guarantor {
  guarantor_contactid: string
  guarantor_name: string
  guarantor_email?: string
  guarantor_isprimary: boolean
  guarantor_id: string
}

export interface Guarantors {
  [gaurantor_contactid: string]: Guarantor
}

export interface GuarantorsPost {
  uuid: string
  iso_loan_agree_review_needed: boolean
  new_partner_email?: string
  guarantors: Guarantors
}

export interface PendingOfferTemp
  extends Omit<PendingOffer, 'accepted_offers'> {
  accepted_offer: AcceptedOffer
  guarantors: Guarantors
}

export interface PricingTierTemp extends Omit<PricingTier, 'pricing_type'> {
  pricing_type: string // could be an enum of valid pricing types..
  // see:
  // https://mulliganfunding.visualstudio.com/mulligan-business-ops/_git/api-partner-portal?path=/src/constants/enums/pricing-type.ts
}

export enum PendingOfferStatus {
  ACTIVE = 'ACTIVE',
  ACCEPTED_SELECT_DOC_DELIVERY_PREFERENCE = 'ACCEPTED_SELECT_DOC_DELIVERY_PREFERENCE',
  INACTIVE = 'INACTIVE',
  INACTIVE_ALL = 'INACTIVE_ALL',
  COMPLETED = 'COMPLETED',
  // PROPOSED FUTURE STATUS TO SUPPORT NEW WORKFLOW
  PROVISIONAL_ACCEPTED = 'PROVISIONAL_ACCEPTED',
}
